<template>
    <div>   
 
        <div class="one-toolbar"> 
            <span class="spanRow" style="display:flex" > 
                <el-input :placeholder="input_hold" v-model="textparam" size="small" @keyup.enter.native="qryTabData" style="width:360px;height:30px;margin-top:2px">
                    <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:140px">
                        <span v-for="item in tabCol" :key='item.key'>
                            <el-option :label="item.title" :value="item.key"></el-option>
                        </span>
                    </el-select>
                </el-input> 
            <div style="margin-left:10px; ">
                <el-radio-group v-model="chkval" size="small" @change="chkChange">
                    <el-radio-button label="ALL">所有</el-radio-button>
                    <el-radio-button label="NO">无路由表单</el-radio-button>
                    <el-radio-button label="YES">有路由表单</el-radio-button>
                </el-radio-group>
            </div>
            </span>
        </div>
        <Table  :data="baseData" 
                :columns="tabCol" 
                border                            
                disabled-hover
                :loading="baseload"
                style="overflow:auto"
                size="small"
                ref="frmtab"
                highlight-row
                @on-row-click="rowClick"
                :height="300"
                class="small-table" 
                >
        </Table>
        <div class="one-toolbar"> 
            <span class="spanRow">
                <el-button @click="addRec()" type="text" icon="el-icon-plus" style="margin-right:20px;color:white"><span style="color:white"> 新增字段</span></el-button>                                
                <el-input :placeholder="input_hold2" v-model="textparam2" size="small" @keyup.enter.native="qryTabData2" style="width:380px;margin-left:10px;height:30px; ">
                    <el-select  v-model="selparam2" slot="prepend" placeholder="请选择列名" clearable @change="elchange2" style="width:140px;height:30px">
                        <span v-for="item in tabCol2" :key='item.key'>
                            <el-option :label="item.title" :value="item.key"></el-option>
                        </span>
                    </el-select>
                </el-input> 
            </span>
        </div>
        <div>
        <el-table  :data="baseData2" 
                border                             
                size="small"
                ref="fldtab"
                :row-key="getRowKey"
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(100, 100, 100, 0.8)"
                highlight-current-row
                @row-click="rowClick2"
                :height="tabHeight" >
            <span v-for="(item,index ) in tabCol2" :key="index" >    
                <el-table-column  v-if ="item.edcss!=='checkbox'"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column>
                <el-table-column  v-if ="item.edcss==='checkbox'"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                    <template  slot-scope="scope" >
                        <el-checkbox v-if="item.key==='THIDE'" disabled true-label="Y"  false-label="N" v-model="scope.row.THIDE" ></el-checkbox>
                        <el-checkbox v-if="item.key==='FHIDE'" disabled true-label="Y"  false-label="N" v-model="scope.row.FHIDE" ></el-checkbox>
                        <el-checkbox v-if="item.key==='NOTNULL'" disabled true-label="Y"  false-label="N" v-model="scope.row.NOTNULL" ></el-checkbox>
                        <el-checkbox v-if="item.key==='GEN'" disabled true-label="Y"  false-label="N" v-model="scope.row.GEN" ></el-checkbox>
                        <el-checkbox v-if="item.key==='DISABLED'" disabled true-label="Y"  false-label="N" v-model="scope.row.DISABLED" ></el-checkbox>
                    </template>
                </el-table-column> 
            </span>
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                show-overflow-tooltip
                width="150">
                <template slot-scope="scope">
                    <el-button size="small"   type="text" icon="el-icon-edit" @click="editRec(scope.row,scope.$index)">编辑</el-button>
                    <el-button size="small"   type="text" icon="el-icon-close" @click="delRec(scope.row,scope.$index)">删除</el-button>   
                </template>
            </el-table-column>   
        </el-table>
        </div>
        <el-dialog  :show-close="false" class="pub_dialog" v-dialogDrag :visible.sync="addModal" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{type==='create'?'新增记录':'修改记录'}}</span>
            </div> 
            <Form ref="addForm" :model="formCol2" :label-width="110"  :rules="ruleValidate"  label-position="right" inline >
                <span v-for="(item,index) in tabFrmCol2" :key="index" >
                    <FormItem :label="index<0?'序号':item.FLDNM" :prop="item.FLDNO" v-if="item.FHIDE==='N'">                                  
                        <el-checkbox border v-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="N" v-model="formCol2[item.FLDNO]" class="colWidth"></el-checkbox>    
                        <Select v-else-if="item.EDCSS==='select' && item.FLDNO==='EDCSS'" v-model="formCol2[item.FLDNO]" class="colWidth" clearable>                            
                            <Option v-for="(itsel,index) in editCss" :value="itsel.SUBNO" :key='index'>{{itsel.CNAME}}</Option>   
                        </Select>
                        <Select v-else-if="item.EDCSS==='select' && item.FLDNO==='GPNM'" filterable v-model="formCol2[item.FLDNO]" class="colWidth" clearable>                            
                            <Option v-for="(itsel,index) in gpnmArr" :value="itsel.CNAME" :key='index'>{{itsel.CNAME}}</Option>   
                        </Select>
                        <Select v-else-if="item.EDCSS==='select' && item.FLDNO==='CSS'" v-model="formCol2[item.FLDNO]" class="colWidth" clearable>                            
                            <Option v-for="(itsel,index) in dataDic" :value="itsel.SUBNO" :key='index'>{{itsel.CNAME}}</Option>   
                        </Select>
                        <Select v-else-if="item.EDCSS==='select' && item.FLDNO==='FLDTY'" v-model="formCol2[item.FLDNO]" class="colWidth" clearable>                            
                            <Option v-for="(itsel,index) in fldty" :value="itsel.code" :key='index'>{{itsel.value}}</Option>   
                        </Select>   
                        <Input v-else type="text" :disabled="item.DISABLED=='Y'?true:false||index==0" v-model="formCol2[item.FLDNO]"  class="colWidth" clearable/>
                    </FormItem>
                </span>
            </Form>
            <div style="height:35px;line-height:30px;border-top:2px solid #ccc;padding-top:4px;  ">
                <span style="float:right">
                    <el-button type="info" size="small"  @click="modal_cancel" style="margin-right:30px">取消</el-button>
                    <el-button  type="primary" size="small"  @click="modal_ok">确定</el-button>
                </span>
            </div>
        </el-dialog>  
    </div>
</template>
<script>
import { getTabColOrData,getBaseData } from '../../api/user'
import {gpnmArray} from '../../api/Select'
export default{  
    name: "sys_formfld",
    data () {
        return {
            addModal:false, //modal 
            chkval:'ALL',
            large:'large',
            textparam:'', // input 绑定值
            selparam:'FRMNM',// select 绑定值
            textparam2:'', // input 绑定值
            selparam2:'',// select 绑定值
            tabCol:[] ,//表列集合
            tabCol2:[] ,//表列集合
            tabFrmCol2:[],
            input_hold:'请输入内容...',
            input_hold2:'请输入内容...',
            baseData:[] ,//表数据
            baseData2:[] ,//表数据
            formArr:[] , //保存取到所有数据
            formCol2:{}, //表格单行记录 model
 
            rowIndex2:'-1', //行号 
            // 初始化信息总条数
            type:'create',
            loading:false,// table加载bool
            baseload:false,
            rowIdseq:'-1',
            lstseq:'',
            modseq:'-1',
            rowFrmnm:'',
            ruleValidate: {},
            notNull:[],
            dataDic:[],//编辑样式数组:对齐方式
            editCss:[] ,//编辑样式数组:表单组件
            gpnmArr:[] ,
            stfgBool:true,
            v_url:this.$store.state.baseUrl+'sysprivs/getTableRecMany', //api请求路径
            v_likeurl:this.$store.state.baseUrl+'sysprivs/getLikeManyRec', //多记录查询 like模式
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            fldty:[{code:'HEAD',value:'表格1'},{code:'HEAD2',value:'表格2'},
                    {code:'HEAD3',value:'表格3'} ,{code:'HEAD4',value:'表格4'},
                    {code:'HEAD5',value:'表格5'} ,{code:'HEAD6',value:'表格6'} ,{code:'TOP',value:'单据头'}
                ]     
        }
    },
    watch: {
        //监听，初始化时获取主表的ID 值 来查询明细记录  
        modseq(){
            this.getFrmFldData('','','','','','',' and idseq='+this.rowIdseq)
        }  
    },
    created () {  
        //分组信息
        gpnmArray().then((res)=>{
            this.gpnmArr =res.data.result
        });  
        getBaseData(this.$store.state.baseUrl+'auth/login/getBaseData','parentno','231','','','v_sys_base','').then(res=>{
            this.dataDic =res.data.result
        })
        getBaseData(this.$store.state.baseUrl+'auth/login/getBaseData','parentno','22','','','v_sys_base','').then(res=>{
            this.editCss =res.data.result
        })     
        this.getTabCol()
        this.getTabCol2()
        this.getFormData()
    },
    mounted () {          
        //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','20','fty','HEAD2','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.ruleValidate =ruleObj
        });
    },
    computed: {
        tabHeight(){
            return window.innerHeight - 470
        }
    },
    methods: {  
        getRowKey(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        }, 
        chkClick(){

        },
        //表单过滤 有无路由
        chkChange(){
            if(this.chkval==='ALL'){
                this.getFormData('','',this.selparam,this.textparam,'','','')
            }else if(this.chkval==='NO'){
                this.getFormData('','',this.selparam,this.textparam,'','',' AND FRMURL IS NULL')
            }if(this.chkval==='YES'){
                this.getFormData('','',this.selparam,this.textparam,'','',' AND FRMURL IS NOT NULL')
            }
        },   
        modal_cancel(){
            this.addModal=false
        },
        //确定
        modal_ok(){
            var vm=this;
            this.notNull.some(item=>{  //forEach 没找到跳出循环，some：return true 跳出lop ,every：return false 跳出
                if (!this.formCol2[item.FLDNO] ){
                    this.stfgBool =false 
                    this.$Message.error(item.FLDNM+'不能为空'); 
                    return true
                }else{
                    this.stfgBool =true  
                }
            })
            if (this.stfgBool) {      
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/tableBodySave?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_json:JSON.stringify(vm.formCol2), p_table:'sys_form_fld',p_idseq:''},
                }).then(res=>{   
                    if(res.data.result =='OK')  {          
                        vm.addModal =false  
 
                        this.qryTabData2()
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                }) 
            }
        },
        addRec(){
            this.addModal =true
            this.type ='create'
            this.formCol2 ={'IDSEQ':this.rowIdseq,'NOTNULL':'N','THIDE':'N','FHIDE':'N','DISABLED':'N','GEN':'N'}
        },
        editRec(row,index){ 
            this.addModal =true
            this.type ='edit'
            this.rowIndex2 =index
        },
        delRec(row,index){
            this.$Modal.confirm({
                title: '删除确认',
                content: '<p>你确定要删除此行记录吗</p>',
                onOk: () => {
                    //发起删除请求
                    var vm=this;
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'sysprivs/delTableRec?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                        data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ,p_table:'sys_form_fld'},
                    }).then(res=>{      
                        if(res.data.code ==='200')  {      
                            this.qryTabData2()    
                        }else{
                            this.$Message.error(res.data.message)
                        }        
                    }) 
                }
            });
        },
        elchange(val){
            let obj ={}
            obj =this.tabCol.find(item=>{
                return item.key===val
            })
            this.input_hold='请输入'+obj.title+' 的值'
        },
        elchange2(val){
            let obj ={}
            obj =this.tabCol2.find(item=>{
                return item.key===val
            })
            this.input_hold2='请输入'+obj.title+' 的值'
        },
        qryTabData(){
            this.getFormData('','',this.selparam,this.textparam,'','','')
            this.chkval ='ALL'
        },
        qryTabData2(){
            this.getFrmFldData(this.selparam2,this.textparam2,'','','','',' and idseq='+this.rowIdseq)
        },
        rowClassName(row,index){
            if (index ===0){
                //  return 'ivu-table-row-highlight'
            }
        },
        //获取表行数据
        rowClick (row ) {               
            this.rowIdseq =row.IDSEQ
            this.rowFrmnm =row.FRMNM
            this.getFrmFldData('','','','','','',' and idseq='+row.IDSEQ)
        },
        rowClick2 (row ) {
            this.formCol2 = JSON.parse(JSON.stringify(row)) //获取行数据           
            this.lstseq=row.LSTSEQ
        },
        //获取表单数据
        getFormData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
            var vm=this
            this.baseload=true
            getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_SYS_FORM',v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_).then((res) => {
                vm.baseData = res.data.result
                this.baseload=false
                if(res.data.result.length>0){
                    this.rowIdseq =res.data.result[0].IDSEQ   
                    this.modseq =this.rowIdseq   
                    this.getFrmFldData(this.selparam2,this.textparam2,'','','','',' and idseq='+this.modseq )         
                }else{
                    this.getFrmFldData(this.selparam2,this.textparam2,'','','','',' and idseq=-1' )
                }                   
            });               
        },
        //获取模块表单数据
        getFrmFldData(v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_){
            var vm=this
            this.baseload =true
            getTabColOrData(this.v_likeurl,this.v_username,this.v_password,'V_SYS_FORM_FLD',v_key,v_value,v_key2,v_value2,v_key3,v_value3,cond_).then((res) => {
                vm.baseData2 = res.data.result
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    this.$refs.fldtab.doLayout();
                })
                this.baseload=false
            });               
        },
        //获取表列头字段
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','20','fty','HEAD','thide','N',' order by sortby,lstseq').then((res) => {
                vm.tabCol = res.data
                vm.tabCol.unshift({ //向数组的开头 添加序列号
                    title: '序号',
                    width:60,
                    render:(h,params)=>{
                        return h("span" ,{
                        },params.index+ 1)
                    }
                })
            });
        },
        //获取表列头字段
        getTabCol2(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','20','fty','HEAD2','','',' order by sortby,lstseq').then((res) => {
                vm.tabFrmCol2 = res.data
                for (let k=0; k<res.data.length; k++){
                    if (res.data[k].THIDE==='N') { // 
                        vm.tabCol2.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,
                            edcss:res.data[k].EDCSS,
                            sortable: res.data[k].sortable?true:false,
                        })
                    }
                }                  
            });
        },
    }
}
</script>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
  .colWidth{
      width:260px;
  }
  .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
 <style lang="less">
  
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
 
 
</style>